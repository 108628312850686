const navItems = document.querySelectorAll('.js-nav__item');
const pageNav = document.querySelector('.js-.page-nav');
const pageNavSentinel = document.querySelector('.js-nav-sentinel');
const sectionTitles = document.querySelectorAll('.js-section-title');
const upOffset = 150;

function updateClasses(element, classesToAdd = [], classesToRemove = []) {
  element.classList.remove(...classesToRemove);
  element.classList.add(...classesToAdd);
}

// Intersection Observer for sticky navigation
const stickyObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (!entry.isIntersecting) {
      navItems.forEach(navItem => navItem.classList.add('is-active-sticky'));
      pageNav.classList.add('stuck');
    } else {
      navItems.forEach(navItem => navItem.classList.remove('is-active-sticky'));
      pageNav.classList.remove('stuck');
    }
  });
}, { rootMargin: '-84px 0% 0% 0%' });
stickyObserver.observe(pageNavSentinel);

// Observer options
const observerOptions = {
  sections: { rootMargin: '-84px 0% -30% 0%', threshold: 0.5 },
  boxes: { rootMargin: '0% 0% -50% 0%' }
};

// Intersection Observer for sections
const observerSections = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const targetClass = entry.target.closest('section').dataset.class;
      const targetElement = document.querySelector(targetClass);

      navItems.forEach(navItem => updateClasses(navItem, [], ['is-active', 'stroke__right-in', 'stroke__left-in']));
      if (targetElement) {
        updateClasses(targetElement, ['is-active', 'stroke__left-in']);
      }
    }
  });
}, observerOptions.sections);

sectionTitles.forEach(section => observerSections.observe(section));

// Observer for boxes
const observerBoxesTriggerOnce = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      Array.from(entry.target.children).forEach(child => child.classList.add('fadeInUp'));
      observer.unobserve(entry.target);
    }
  });
}, observerOptions.boxes);

const boxes = document.querySelectorAll('.js-waypoint__boxes');
boxes.forEach(box => observerBoxesTriggerOnce.observe(box));

// Smooth scrolling for navigation items
navItems.forEach(navItem => {
  navItem.addEventListener('click', event => {
    event.preventDefault();
    const target = document.querySelector(event.target.hash);
    if (target) {
      const offset = target.offsetTop - upOffset;
      window.scrollTo({ top: offset, behavior: 'smooth' });
    }
  });
});
